import { BLOGS } from "../../../../Utilities/APIs/APIs";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";

function UpdateApproved({
  targetID,
  setUpdateApproved,
  setShowModal,
  triggerFetch,
}) {
  const axiosInstance = useAxiosInstance();

  async function handleBlog(id) {
    const res = await axiosInstance.patch(BLOGS + "approvedBlog/" + id, {});

    if (res.data) {
      setShowModal(false);
      setUpdateApproved(false);
      triggerFetch();
    }
  }

  return (
    <div className="delete_item">
      <div className="approve_content">
        <h1>Are you sure?</h1>
        <p>Please confirm you want to Approve this Writeup.</p>
        <div className="approve_container">
          <button onClick={() => handleBlog(targetID)} className="approve">
            Approve
          </button>
          <button
            onClick={() => {
              setShowModal(false);
            }}
            className="approve"
          >
            Cancel
          </button>

          {/* <button
            className="cancel"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Cancel
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default UpdateApproved;
