import { useState, useEffect } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { BLOGS } from "../../../../Utilities/APIs/APIs";
import Image from "../../../Partials/Elements/Image/Image";

function ViewBlogPost({ targetID }) {
  const [content, setContent] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetCategory() {
      const { data } = await axiosInstance.get(
        BLOGS + "getSingleBlog/" + targetID
      );
      console.log("team12345", data);
      setContent(data);
    }
    fetchAndSetCategory();
  }, [axiosInstance, targetID]);

  return (
    <div className="crud_view_content">
      {content && (
        <>
          <h1>Title</h1>
          <p>{content?.name}</p>

          <h1>Image</h1>
          <Image imgLink={content.image} imgAlt={"Team Image"} />

          <h1>Short Description</h1>
          <p>{content?.shortDescription}</p>

          <h1>Description</h1>
          <p dangerouslySetInnerHTML={{ __html: content?.description }}></p>

          <h1> Is Approved</h1>
          <p>{content?.isApproved ? "True" : "False"}</p>

          <h1> Views</h1>
          <p>{content?.views}</p>

          <h1>Likes</h1>
          <p>{content?.likes}</p>
        </>
      )}
    </div>
  );
}

export default ViewBlogPost;
