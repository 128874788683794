import { useContext, useEffect, useState } from "react";
import AppContext from "../../../../AppContext/AppContext";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import { BLOGS } from "../../../../Utilities/APIs/APIs";
import CRUDTable from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import Modal from "../../../Partials/Elements/Modal/Modal";
import DeleteItem from "../../../Partials/Layouts/DeleteItem/DeleteItem";
import ImageCell from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ImageCell/ImageCell";
import ViewButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CreateTeamForm from "../Forms/CreateBlogPostForm";
import UpdateBlogPost from "../Forms/UpdateBlogPost";

import ViewBlogPost from "../Forms/ViewBlogPost";
import CRUDButton from "../../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import UpdateApproved from "./UpdateApproved";
import FilterSelect from "../../../Partials/Layouts/Forms/FilterSelect/FilterSelect";
import ArchiveButton from "../../../Partials/Layouts/CRUDs/ArchiveButton/ArchiveButton";
import ArchiveItem from "../../../Partials/Layouts/ArchiveItem/ArchiveItem";
import { HiBadgeCheck } from "react-icons/hi";

function BlogPost() {
  const [content, setContent] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteSection, setShowDeleteSection] = useState(false);
  const [updateApproved, setUpdateApproved] = useState(false);
  const [toggleFetch, setToggleFetch] = useState(false);
  const { employee } = useContext(AppContext);
  const [archive, setArchive] = useState(false);
  const [filter, setFilter] = useState("active");
  const [showArchiveSection, setShowArchiveSection] = useState(false);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetContent() {
      const { data } = await axiosInstance.get(BLOGS + `?filter=${filter}`);
      setContent(data);
    }
    fetchAndSetContent();
  }, [toggleFetch, axiosInstance, showModal, filter]);

  function handleClose() {
    setShowModal(false);
    setShowCreateForm(false);
    setShowViewSection(false);
    setShowUpdateForm(false);
    setShowDeleteSection(false);
    setUpdateApproved(false);
    setShowArchiveSection(false);
  }

  function triggerFetch() {
    setToggleFetch((prevState) => !prevState);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            <h1 className="heading">{`Writeups (${content?.length} in total)`}</h1>

            <FilterSelect filter={filter} setFilter={setFilter} />
          </div>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="Name" />
            <CRUDth th="Image" />
            <CRUDth th="Actions" />
          </CRUDTableHeader>
          <tbody>
            {content?.map((item) => (
              <CRUDTableRow key={item._id}>
                <ShortTextCell text={item?.name} />
                <ImageCell
                  imgSrc={process.env.REACT_APP_SPACES_URL + item.image}
                  alt={"team member"}
                />

                <td className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={item._id}
                    setTargetID={setTargetID}
                  />

                  {!item?.isApproved && (
                    <CRUDButton
                      handleClick={() => {
                        setShowModal(true);
                        setUpdateApproved(true);
                        setTargetID(item._id);
                      }}
                    >
                      <HiBadgeCheck />
                    </CRUDButton>
                  )}
                  <ArchiveButton
                    setShowModal={setShowModal}
                    setShowDeleteSection={setShowArchiveSection}
                    targetID={item._id}
                    isArchive={item?.isActive}
                    setTargetID={setTargetID}
                    setArchive={setArchive}
                  />
                </td>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showCreateForm && `Create Writeup`) ||
            (showUpdateForm && `Update Writeup`) ||
            (showDeleteSection && `Delete Writeup`)
          }
        >
          {showCreateForm && (
            <CreateTeamForm
              employee={employee}
              setShowCreateForm={setShowCreateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}
          {showUpdateForm && (
            <UpdateBlogPost
              targetID={targetID}
              employee={employee}
              setShowUpdateForm={setShowUpdateForm}
              setShowModal={setShowModal}
              triggerFetch={triggerFetch}
            />
          )}

          {showDeleteSection && (
            <DeleteItem
              api={BLOGS}
              targetID={targetID}
              employee={employee}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowDeleteSection}
              hasName
              triggerFetch={triggerFetch}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Writeup`}
        >
          <ViewBlogPost targetID={targetID} />
        </Modal>
      )}
      {showModal && updateApproved && (
        <Modal view handleClose={handleClose} modalHeading={`Approve Writeup`}>
          <UpdateApproved
            setUpdateApproved={setUpdateApproved}
            targetID={targetID}
            setShowModal={setShowModal}
            triggerFetch={triggerFetch}
          />
        </Modal>
      )}
      {showModal && showArchiveSection && (
        <Modal view handleClose={handleClose} modalHeading={`Archive Writeup`}>
          <ArchiveItem
            api={BLOGS + "archive/"}
            targetID={targetID}
            employee={employee}
            setShowModal={setShowModal}
            setShowArchiveSection={setShowArchiveSection}
            hasName
            triggerFetch={triggerFetch}
            isArchive={archive}
          />
        </Modal>
      )}
    </>
  );
}

export default BlogPost;
