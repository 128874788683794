import { useEffect, useState } from "react";
import useAxiosInstance from "../../../../Utilities/Hooks/useAxiosInstance";
import Form from "../../../Partials/Layouts/Forms/Form";
import ImageInput from "../../../Partials/Layouts/Forms/FormInputs/ImageInput/ImageInput";
import FormSubmitButton from "../../../Partials/Layouts/Forms/FormSubmitButton/FormSubmitButton";
import { MdOutlineFileUpload } from "react-icons/md";
import CustomEditor from "../../../Partials/Elements/CustomEditor/CustomEditor";
import { about_API } from "../../../../Utilities/APIs/APIs";

function UpdateAboutForm({
  employee,
  targetID,
  setShowUpdateForm,
  setShowModal,
}) {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);

  const axiosInstance = useAxiosInstance();

  useEffect(() => {
    async function fetchAndSetItemToUpdate() {
      const { data } = await axiosInstance.get(
        about_API + "aboutHero/getSingleAboutHero/" + targetID
      );
      console.log("hero data12345:", data);
      setTitle(data.title);
      setDescription(data.description);
      setImage(data.image);
    }

    fetchAndSetItemToUpdate();
  }, [targetID, axiosInstance]);

  async function handleSubmit(e) {
    e.preventDefault();

    const config = {
      headers: {
        Authorization: `Bearer ${employee.token}`,
      },
    };

    const itemData = new FormData();

    itemData.append("title", title);
    itemData.append("description", description);
    itemData.append("image", image);

    const response = await axiosInstance.patch(
      about_API + "aboutHero/" + targetID,
      itemData,
      config
    );

    if (response.status === 200) {
      setTitle("");
      setDescription("");
      setImage(null);

      setShowUpdateForm(false);
      setShowModal(false);
    }
  }

  return (
    <Form onSubmit={handleSubmit} hasImage>
      <div style={{ color: "black" }}>
        <label htmlFor="" style={{ color: "white" }}>
          <strong>Title</strong>
        </label>
        <CustomEditor setState={setTitle} data={title} />
      </div>

      <div style={{ color: "black" }}>
        <label htmlFor="" style={{ color: "white" }}>
          <strong>Description</strong>
        </label>
        <CustomEditor data={description} setState={setDescription} />
      </div>

      <ImageInput
        heading={"Upload Image"}
        state={image}
        setState={setImage}
        allowUpdateImage={true}
      >
        <div
          style={{
            border: "1px dotted gray",
            padding: "2rem",
            display: "grid",
            placeItems: "center",
            cursor: "pointer",
          }}
          className="uploadArea"
        >
          <MdOutlineFileUpload style={{ fontSize: "4rem" }} />
        </div>
      </ImageInput>

      <FormSubmitButton text="Update" />
    </Form>
  );
}

export default UpdateAboutForm;
