import { useState } from "react";
import { HiBadgeCheck } from "react-icons/hi";
import CRUDTable from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTable";
import CRUDTableHeader from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDTableHeader";
import CRUDth from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableHeader/CRUDth/CRUDth";
import CRUDTableRow from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableRow/CRUDTableRow";
import ShortTextCell from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDTable/CRUDTableCells/ShortTextCell/ShortTextCell";
import ViewButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/ViewButton/ViewButton";
import CRUDButton from "../../Partials/Layouts/CRUDs/CRUDBoilerPlate/CRUDTableBoilerPlate/CRUDButtons/CRUDButton";
import Modal from "../../Partials/Elements/Modal/Modal";
import useFetchJournals from "./Hooks/useFetchJournals";
import ViewJournal from "./components/ViewJournal";
import ApproveJournal from "./components/ApproveJournal";
import ActiveJournal from "./components/ActiveJournal";
import { FaArchive } from "react-icons/fa";
import { RiInboxArchiveLine } from "react-icons/ri";

// import ViewEvent from "./ViewEvent";

function Journal() {
  const [showModal, setShowModal] = useState(false);
  const [targetID, setTargetID] = useState(null);
  const [showApprove, setShowApprove] = useState(false);
  const [showViewSection, setShowViewSection] = useState(false);
  const [showActiveModal, setShowActiveModal] = useState(false);

  const journals = useFetchJournals();

  function handleClose() {
    setShowModal(false);
    setShowViewSection(false);
    setShowApprove(false);
  }

  return (
    <>
      <div className="crud_holder">
        <div className="body_header">
          <h1 className="heading">{`Event Ticket (${journals?.length} in total)`}</h1>
        </div>
        <CRUDTable>
          <CRUDTableHeader>
            <CRUDth th="No" />
            <CRUDth th="Title" />
            <CRUDth th="Status" />
            <CRUDth th="Action" />
          </CRUDTableHeader>
          <tbody>
            {journals?.map((journal, index) => (
              <CRUDTableRow key={journal._id}>
                <ShortTextCell text={index + 1} />
                <ShortTextCell
                  text={
                    journal?.title.length > 50
                      ? journal.title.slice(0, 50) + "..."
                      : journal.title
                  }
                />
                <ShortTextCell
                  text={journal?.isApproved ? "Approved" : "Unapproved"}
                />
                <div className="action_button_cell">
                  <ViewButton
                    setShowModal={setShowModal}
                    setShowViewSection={setShowViewSection}
                    targetID={journal._id}
                    setTargetID={setTargetID}
                  />
                  {journal?.isActive ? (
                    <CRUDButton
                      handleClick={() => {
                        setShowModal(true);
                        setShowActiveModal(true);
                        setTargetID(journal._id);
                      }}
                    >
                      <RiInboxArchiveLine />
                    </CRUDButton>
                  ) : (
                    <CRUDButton
                      handleClick={() => {
                        setShowModal(true);
                        setShowActiveModal(true);
                        setTargetID(journal._id);
                      }}
                    >
                      <FaArchive />
                    </CRUDButton>
                  )}

                  {!journal.isApproved && (
                    <CRUDButton
                      handleClick={() => {
                        setShowModal(true);
                        setShowApprove(true);
                        setTargetID(journal._id);
                      }}
                    >
                      <HiBadgeCheck />
                    </CRUDButton>
                  )}
                </div>
              </CRUDTableRow>
            ))}
          </tbody>
        </CRUDTable>
      </div>
      {showModal && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={
            (showActiveModal && `Active Journal`) ||
            (showApprove && `Approve Journal`)
          }
        >
          {showApprove && (
            <ApproveJournal
              targetID={targetID}
              setShowModal={setShowModal}
              setShowApproveModal={setShowApprove}
            />
          )}

          {showActiveModal && showModal && (
            <ActiveJournal
              targetID={targetID}
              setShowModal={setShowModal}
              setShowDeleteSection={setShowActiveModal}
            />
          )}
        </Modal>
      )}

      {showModal && showViewSection && (
        <Modal
          view
          handleClose={handleClose}
          modalHeading={showViewSection && `View Event Item`}
        >
          <ViewJournal targetID={targetID} />
        </Modal>
      )}
    </>
  );
}

export default Journal;
